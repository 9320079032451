import React from "react";
import { AllSections, Sections } from "@components/Shared/AllSections/AllSections";
import Layout from "@components/Shared/Templates/Layout";
import { useApplication } from "@ryerson/frontend.application";
import ContactUsRefactor from "@components/Shared/ContactUsRefactor/ContactUs";
import { DefaultContactUsContent } from "@components/Shared/ContactUsRefactor/Content";
import MetalExperts from "@components/Shared/YourMetalExperts/YourMetalExperts";
import { YourStaticContent } from "@components/Shared/YourMetalExperts/Content";
import { Media } from "@ryerson/frontend.layout";
import MetalResourcesHero, { MetalResourcesHeroProps } from "@components/MetalResources/Hero/Hero";
import ToolsCalculators, {
	ToolsCalculatorsProps,
} from "@components/MetalResources/ToolsCalculators/ToolsCalculators";
import MetalMarketIntelligence, {
	MetalMarketIntelligenceProps,
} from "@components/MetalResources/MetalMarketIntelligence/MetalMarketIntelligence";
import References from "@components/MetalResources/References/References";
import MetalResourcesTutorials, {
	MetalResourcesTutorialsProps,
} from "@components/MetalResources/Tutorials/Tutorials";
import SubNavigation, {
	NavigableItem,
} from "@components/BarTubeStructuralProcessing/SubNavigation/SubNavigation";
import Meta, { MetaContent } from "@components/Shared/model/MetaHelmet";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { IgniteYourCareerContent } from "@components/Careers/IgniteYourCareer/IgniteYourCareer";

const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "Metal Resources - Ryerson",
		description: `From frequently asked questions to a glossary of common metal terms, to The Gauge, our metal resources can help answer your most pressing questions.`,
		url: `/metal-resources`,
		company: true,
	},
	fr: {
		title: "Metal Resources - Ryerson",
		description: `From frequently asked questions to a glossary of common metal terms, to The Gauge, our metal resources can help answer your most pressing questions.`,
		url: `/metal-resources`,
		company: true,
	},
	es: {
		title: "Metal Resources - Ryerson",
		description: `From frequently asked questions to a glossary of common metal terms, to The Gauge, our metal resources can help answer your most pressing questions.`,
		url: `/metal-resources`,
		company: true,
	},
};

const HeroContent: LanguageContent<MetalResourcesHeroProps> = {
	en: {
		title: "Metal Resources",
	},
	fr: {
		title: "Ressources métallurgiques",
	},
	es: {
		title: "Recursos de metal",
	},
};

const MarketIntelligenceContent: LanguageContent<MetalMarketIntelligenceProps> = {
	en: {
		title: "Metal Market Intelligence",
		imageUrl: "/images/metal-resources/metalMarketNewBanner.jpg",
		mobileImageUrl: "/images/WhyRyersonHeroImageMobile.jpg",
		mobileImageText: "Metal Market \n Intelligence Powered by",
		description: `Beyond the metal, Ryerson is a leader in market intelligence to help you make the most informed decisions as you navigate the Metalverse.`,
		actionText: ["Learn More About", "Metal Market Intelligence"],
		actionLink: "/metal-resources/metal-market-intelligence",
		blogCategories: [],
	},
	fr: {
		title: "Information sur le marché du métal",
		imageUrl: "/images/metal-resources/metalMarketNewBanner.jpg",
		mobileImageUrl: "/images/WhyRyersonHeroImageMobile.jpg",
		mobileImageText: "Information sur \n le marché du métal",
		description: `À titre de chef de file du renseignement sur le marché, Ryerson peut éclairer vos décisions et vous guider dans le Metalverse.`,
		actionText: ["En savoir plus sur", "Information sur le marché du métal"],
		actionLink: "/metal-resources/metal-market-intelligence",
		blogCategories: [],
	},
	es: {
		title: "Inteligencia en el mercado de metales",
		imageUrl: "/images/metal-resources/metalMarketNewBanner.jpg",
		mobileImageUrl: "/images/WhyRyersonHeroImageMobile.jpg",
		mobileImageText: "Inteligencia en \n el mercado de metales",
		description: `Más allá del metal, Ryerson es líder en inteligencia de mercado para ayudarle a tomar las decisiones más informadas mientras navega por Metalverse.`,
		actionText: ["Conozca más acerca de", "la información del mercado de metales"],
		actionLink: "/metal-resources/metal-market-intelligence",
		blogCategories: [],
	},
};

const ReferencesContent: LanguageContent<IgniteYourCareerContent> = {
	en: {
		title1: "",
		title2: "References",
		description:
			"From frequently asked questions to a glossary of common metal terms, our resources can help answer your most pressing questions.",
		cards: [
			{
				desktopImage: "/images/metal-resources/FAQ.jpg",
				mobileImage: "/images/metal-resources/FAQ.jpg",
				title: "FAQs",
				actionUrl: "/metal-resources/references/faq",
				actionLabel: "Frequently Asked Questions",
				description:
					"How can we help you? Please see our most frequently asked questions below for more information.",
			},
			{
				desktopImage: "/images/metal-resources/Glossary.jpg",
				mobileImage: "/images/metal-resources/Glossary.jpg",
				title: "Glossary",
				actionUrl: "/metal-resources/references/glossary",
				actionLabel: "Glossary",
				description:
					"This ever-expanding appendix is your guide to the most commonly used terms and phrases in metal.",
			},
		],
	},
	fr: {
		title1: "",
		title2: "Références",
		description:
			"Des questions fréquemment posées à un glossaire des termes courants relatifs aux métaux, nos ressources peuvent vous aider à répondre à vos questions les plus urgentes.",
		cards: [
			{
				desktopImage: "/images/metal-resources/FAQ.jpg",
				mobileImage: "/images/metal-resources/FAQ.jpg",
				title: "FAQs",
				actionUrl: "/metal-resources/references/faq",
				actionLabel: "Foire aux questions",
				description:
					"Comment pouvons-nous vous aider? Veuillez consulter notre foire aux questions ci-dessous pour plus de renseignements.",
			},
			{
				desktopImage: "/images/metal-resources/Glossary.jpg",
				mobileImage: "/images/metal-resources/Glossary.jpg",
				title: "Glossaire",
				actionUrl: "/metal-resources/references/glossary",
				actionLabel: "Glossaire",
				description:
					"Ce glossaire en constante expansion fournit des définitions pour les termes les plus utilisés dans le secteur métallurgique.",
			},
		],
	},
	es: {
		title1: "",
		title2: "Referencias",
		description:
			"Desde preguntas frecuentes hasta un glosario de términos comunes de metales, nuestros recursos pueden ayudar a responder la mayoría de sus preguntas apremiantes.",
		cards: [
			{
				desktopImage: "/images/metal-resources/FAQ.jpg",
				mobileImage: "/images/metal-resources/FAQ.jpg",
				title: "FAQs",
				actionUrl: "/metal-resources/references/faq",
				actionLabel: "Preguntas frecuentes",
				description:
					"¿Cómo lo podemos ayudar? Revise nuestras preguntas frecuentes a continuación para encontrar más información.",
			},
			{
				desktopImage: "/images/metal-resources/Glossary.jpg",
				mobileImage: "/images/metal-resources/Glossary.jpg",
				title: "Glosario",
				actionUrl: "/metal-resources/references/glossary",
				actionLabel: "Glosario",
				description:
					"Este apéndice en constante expansión es su guía para los términos y frases más utilizados en la industria del metal.",
			},
		],
	},
};

const ToolsCalculatorsContent: LanguageContent<ToolsCalculatorsProps> = {
	en: {
		title: "Tools & Calculators",
		mainDescription: `How much steel do you need for that project? Can you save money with a custom cut? Check out these resources to help you answer these and other project-related questions.`,
		secondaryContent: [
			{
				title: "Weight Calculator",
				description: `The calculator can help you estimate the weight of metal parts in various 
                    quantities. Simply select the material, alloy, and shape, and enter number of pieces 
                    followed by dimensions. Try it now.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
				link: "/metal-resources/tools-calculators/weight-calculator",
				linkText: "More about Weight Calculator",
			},
			{
				title: "Sheet Scrap \n Calculator",
				description: `We offer custom cut-to-size sheets in many products, gauges and finishes. 
                    Curious as to how much money you can save with a custom size? Click here to learn more.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
				link: "/metal-resources/tools-calculators/sheet-scrap-calculator",
				linkText: "More about Sheet Scrap Calculator",
			},
		],
	},
	fr: {
		title: "Outils et calculateurs",
		mainDescription: `De quelle quantité d'acier avez-vous besoin pour ce projet? Pouvez-vous économiser de l'argent avec une coupe sur mesure? Consultez ces ressources pour vous aider à répondre à ces questions et à répondre à d'autres questions liées au projet.`,
		secondaryContent: [
			{
				title: "Calculateur de poids",
				description: `Le calculateur peut vous aider à estimer le poids des pièces de métal en 
                    diverses quantités. Sélectionnez simplement le matériau, l'alliage et la forme, puis 
                    entrez le nombre de pièces, suivi des dimensions. Essayez maintenant.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
				link: "/metal-resources/tools-calculators/weight-calculator",
				linkText: "En savoir plus sur le calculateur de poids",
			},
			{
				title: "Calculateur \n de déchets",
				description: `Nous offrons des feuilles coupées sur mesure dans de nombreux matériaux, calibres 
                    et finitions. Vous vous demandez combien d’argent vous pouvez économiser en achetant 
                    un format sur mesure? Remplissez les renseignements ci-dessous et cliquez sur 
                    Calculer. Demandez ensuite un devis pour obtenir exactement ce dont vous avez besoin.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
				link: "/metal-resources/tools-calculators/sheet-scrap-calculator",
				linkText: "En savoir plus sur le calculateur de déchets",
			},
		],
	},
	es: {
		title: "Herramientas y calculadoras",
		mainDescription: `¿Cuánto acero necesita para ese proyecto? ¿Puede ahorrar dinero con un corte personalizado? Revise estos recursos para ayudar a contestar estas y otras preguntas relacionadas con el proyecto.`,
		secondaryContent: [
			{
				title: "Calculador de Peso",
				description: `El calculador puede ayudarle a estimar el peso de las partes metálicas en 
                    diversas cantidades. Tan solo seleccione el material, aleación y forma, e ingrese 
                    la cantidad de piezas seguido de las dimensiones. Inténtelo ahora mismo.`,
				imageUrl: "/images/metal-resources/WeightCalculator.jpg",
				link: "/metal-resources/tools-calculators/weight-calculator",
				linkText: "Más Sobre Calculadora de Peso",
			},
			{
				title: "Calculador de \n Tamaños de Hoja",
				description: `Le ofrecemos hojas de tamaños personalizados en diversos productos, calibres 
                    y acabados. ¿Quiere saber cuánto dinero se ahorraría con un tamaño personalizado? 
                    Introduzca la información a continuación y presione Calcular. Luego solicite una 
                    cotización para obtener justo lo que necesita.`,
				imageUrl: "/images/metal-resources/SheetScrapCalculator.jpg",
				link: "/metal-resources/tools-calculators/sheet-scrap-calculator",
				linkText: "Más Sobre Calculadora de Tamaños de Hoja",
			},
		],
	},
};

const TutorialsContent: LanguageContent<MetalResourcesTutorialsProps> = {
	en: {
		title: "Tutorials",
		description:
			"These video tutorials provide in-depth walkthroughs on ryerson.com functionality, focusing on features such as how to access our available inventory, place an order online and check its status, as well as pull a material test report.",
		actionText: ["View", "All Tutorials"],
		actionLink: "/metal-resources/tutorials",
		imageUrl: "/images/metal-resources/tutorials.jpg",
	},
	fr: {
		title: "Tutoriels",
		description:
			"Ces tutoriels vidéo présentent des procédures approfondies sur les fonctionnalités de ryerson.com en mettant l'accent sur les caractéristiques telles que l'accès à nos stocks disponibles, la procédure de commande en ligne et de vérification du statut, ainsi que la récupération d'un rapport d'essai de matériau.",
		actionText: ["Voir tous", "les tutoriels"],
		actionLink: "/metal-resources/tutorials",
		imageUrl: "/images/metal-resources/tutorials.jpg",
	},
	es: {
		title: "Tutoriales",
		description:
			"Estos videos tutoriales proporcionan guías paso a paso detalladas sobre las funcionalidades de ryerson.com, y se enfocan en características tales como el acceso a nuestro inventario disponible, hacer un pedido en línea y revisar su estado, así como obtener un reporte de pruebas de materiales.",
		actionText: ["Ver todos", "los tutoriales"],
		actionLink: "/metal-resources/tutorials",
		imageUrl: "/images/metal-resources/tutorials.jpg",
	},
};

export default () => {
	const {
		localization: { language },
	} = useApplication();

	const sections: Sections[] = [];
	const subNav: NavigableItem[] = [];

	sections.push({
		title: MarketIntelligenceContent[language].title,
		cmp: <MetalMarketIntelligence {...MarketIntelligenceContent[language]} />,
	});
	subNav.push({
		id: MarketIntelligenceContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: MarketIntelligenceContent[language].title,
	});

	sections.push({
		title: ReferencesContent[language].title2,
		cmp: <References content={ReferencesContent} />,
	});
	subNav.push({
		id: ReferencesContent[language].title2.toLowerCase().replace(/\s+/g, "-"),
		title: ReferencesContent[language].title2,
	});

	sections.push({
		title: ToolsCalculatorsContent[language].title,
		cmp: <ToolsCalculators {...ToolsCalculatorsContent[language]} />,
	});
	subNav.push({
		id: ToolsCalculatorsContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: ToolsCalculatorsContent[language].title,
	});

	sections.push({
		title: TutorialsContent[language].title,
		cmp: <MetalResourcesTutorials {...TutorialsContent[language]} />,
	});
	subNav.push({
		id: TutorialsContent[language].title.toLowerCase().replace(/\s+/g, "-"),
		title: TutorialsContent[language].title,
	});

	sections.push({ title: YourStaticContent[language].navLabel, cmp: <MetalExperts /> });
	sections.push({
		title: DefaultContactUsContent[language].title,
		cmp: <ContactUsRefactor oneButtonVariant={false} />,
		hide: true,
	});

	return (
		<Layout>
			<Meta content={MetaObject[language]} />
			<MetalResourcesHero {...HeroContent[language]} />
			<Media greaterThanOrEqual="lg">
				<SubNavigation navigableItems={subNav} type="primary" />
			</Media>
			<AllSections sections={sections} />
			<Media lessThan="lg">
				<ContactUsRefactor
					oneButtonVariant={false}
					background="secondary"
					withImage={false}
				/>
			</Media>
		</Layout>
	);
};
