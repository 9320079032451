import React from "react";
import { useApplication } from "@ryerson/frontend.application";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { FoCCompanyContent } from "@components/FamilyOfCompanies/FamilyOfCompaniesRefactor/Company";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { ButtonLink } from "@ryerson/frontend.navigation";
import { Link } from "@ryerson/frontend.navigation";

type StaticFoCContent = {
	learnMore: string;
};

const StaticContent: LanguageContent<StaticFoCContent> = {
	en: {
		learnMore: "Learn More",
	},
	fr: {
		learnMore: "En savoir plus",
	},
	es: {
		learnMore: "Aprende más",
	},
};

export type CompanyProps = {
	content: FoCCompanyContent;
};

const Container = styled.div`
	max-height: 360px;
	max-width: 360px;
	height: 360px;
	width: 360px;
	margin-top: 30px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	cursor: pointer;
	transition: background 1s ease-in-out;
	&:hover {
		background-image: none; /* Removes the original background */
		background-color: #002855; /* Solid blue background */

		> .logo-container .logo {
			opacity: 0;
			visibility: hidden;
			transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
		}
	}
	z-index: 10;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: absolute;
`;

const TextContainer = styled.div`
	position: absolute;
	top: 0;
	text-align: center;
	margin: 40px 0px;
	width: 100%;
	box-sizing: border-box;
	padding: 0px 35px;
	visibility: hidden;
	transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
	${Container}:hover & {
		opacity: 1;
		visibility: visible;
	}
`;

const ButtonLinkContainer = styled.div`
	text-align: center;
	width: 100%;
	margin-top: 150px;
`;

const CompanyCard: React.FC<CompanyProps> = ({ content }) => {
	const { theme } = useTheme();

	const {
		localization: { language },
	} = useApplication();

	const logoStyles = css`
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 60%;
		max-height: 45%;
		transition: opacity 1s ease-in-out;
		opacity: 1;
		z-index: 0;
	`;

	return (
		<>
			<Link to={content.slug}>
				<Container
					css={css`
						background-image: url(${content.mainFoCImage.file.url}),
							linear-gradient(
								${theme.colors.primary.darkGray},
								${theme.colors.primary.darkGray}
							);
					`}
				>
					<LogoContainer className="logo-container">
						<img
							src={content.logo.file.url}
							alt={content.companyName}
							css={logoStyles}
							className="logo"
						/>
					</LogoContainer>
					<TextContainer
						className="text-container"
						css={css`
							transition: visibility 1.5s ease-in-out, opacity 1.5s ease-in-out;
						`}
					>
						<Typography size="lg" color={theme.colors.primary.white}>
							{content.mainFoCBlurb}
						</Typography>
						<ButtonLinkContainer>
							<ButtonLink
								label={StaticContent[language].learnMore}
								to={"about-us/family-of-companies/" + content.slug}
								size="sm"
								type="tertiary"
							/>
						</ButtonLinkContainer>
					</TextContainer>
				</Container>
			</Link>
		</>
	);
};

export default CompanyCard;
