import React from "react";
import styled from "@emotion/styled";
import { SocialMediaContent } from "./Content";
import { css } from "@emotion/react";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { Link } from "@ryerson/frontend.navigation";

export type SocialMediaBarItems = {
	facebook: string;
	linkedIn: string;
	email: string;
	youtube: string;
};

export type SocialMediaBarProps = {
	orientation: "horizontal" | "vertical";
	type: "primary" | "secondary" | "tertiary";
	color?: string;
	content?: SocialMediaBarItems;
};

const SocialMediaContainer = styled.div`
	display: block;
	text-align: center;
`;

const SocialIcon = styled.div`
	display: inline-block;
	box-sizing: border-box;
	line-height: 20px;
`;

const SocialMediaComponent: React.FC<SocialMediaBarProps> = ({
	orientation = "horizontal",
	content,
	color,
	type = "primary",
}) => {
	const { theme } = useTheme();
	if (!content) content = SocialMediaContent;
	const orientationIconStyling = css`
		margin-right: ${orientation === "vertical" ? "0px" : "12px"};
		margin-bottom: ${orientation === "vertical" ? "6px" : "0px"};
	`;
	const iconStyling = css`
		cursor: pointer;
	`;
	return (
		<SocialMediaContainer
			css={css`
				height: ${orientation === "vertical" ? "auto" : "20px"};
				width: ${orientation === "vertical" ? "20px" : "100%"};
				text-align: ${orientation === "vertical" ? "center" : "left"};
			`}
		>
			<SocialIcon css={orientationIconStyling}>
				<Link to={content.facebook} gatsby={false}>
					<Icon
						css={iconStyling}
						icon="social-facebook"
						size="sm"
						color={color ? color : theme.colors[type].text}
					/>
				</Link>
			</SocialIcon>
			<SocialIcon css={orientationIconStyling}>
				<Link to={content.linkedIn} gatsby={false}>
					<Icon
						css={iconStyling}
						icon="social-linked-in"
						size="sm"
						color={color ? color : theme.colors[type].text}
					/>
				</Link>
			</SocialIcon>
			<SocialIcon css={orientationIconStyling}>
				<Link to={content.youtube} gatsby={false}>
					<Icon
						css={iconStyling}
						icon="social-youtube"
						size="sm"
						color={color ? color : theme.colors[type].text}
					/>
				</Link>
			</SocialIcon>
			<SocialIcon css={orientationIconStyling}>
				<Link to={content.email} gatsby={false}>
					<Icon
						css={iconStyling}
						icon="mail"
						size="sm"
						color={color ? color : theme.colors[type].text}
					/>
				</Link>
			</SocialIcon>
		</SocialMediaContainer>
	);
};

export default SocialMediaComponent;
