import { MoreThanMetalProps } from "./MoreThanMetal";
import { LanguageContent } from "@components/Shared/model/LanguageContent";

export const MoreThanMetalContent: LanguageContent<MoreThanMetalProps> = {
	en: {
		title: "Enter",
		subtitle: "The Metalverse",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"Imagine having every metal need within reach—at Ryerson that’s what we call The Metalverse. Whether you’re a manufacturer searching for the ideal alloy, a fabricator requiring precision processing, or a business adapting to market changes, The Metalverse offers unparalleled reliability and success.",
				"With over 70,000 products and a full spectrum of processing capabilities, Ryerson ensures you get precisely what you need, when you need it—all at competitive prices.",
			],
			metalSolutions: [
				{
					title: "180+",
					context: "Years experience delivering value and service in metal",
				},
				{
					title: "100+",
					context: `Strategically located depots, processing and service centers across North 
                    America and China`,
				},
				{
					title: "70K",
					context: `Stainless steel, aluminum and carbon products`,
				},
			],
		},
		linkText: "Learn more about",
	},
	fr: {
		title: "Entrez ",
		subtitle: "dans le Metalverse",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"Imaginez que tous vos besoins en métal soient à portée de main… Chez Ryerson, c’est ce que nous appelons le Metalverse. Que vous soyez un fabricant à la recherche de l’alliage idéal, un transformateur ayant besoin d’un traitement de précision ou une entreprise s’adaptant aux changements du marché, le Metalverse offre une fiabilité et un succès inégalés.",
				"Avec plus de 70 000 produits et une gamme complète de capacités de traitement, Ryerson s’assure que vous obtenez exactement ce qu’il vous faut, au bon moment et à prix concurrentiels.",
			],
			metalSolutions: [
				{
					title: "180+",
					context:
						"Nous offrons de la valeur et du service dans le domaine de la métallurgie depuis plus de 180 ans.",
				},
				{
					title: "100+",
					context: `Nous comptons plus de 100 entrepôts, centres de traitement et de service situés dans des lieux stratégiques en Amérique du Nord et en Chine.`,
				},
				{
					title: "70K",
					context: `Produits en acier inoxydable, aluminium et carbone`,
				},
			],
		},
		linkText: "En savoir plus sur",
	},
	es: {
		title: "Entre ",
		subtitle: "al Metalverse",
		url: "/why-ryerson/customized-metal-solutions#more-than-metal",
		data: {
			description: [
				"Imagínese tener todos los metales a su alcance; en Ryerson a eso le llamamos Metalverse. No importa si es un fabricante que busca la aleación ideal, un fabricante que requiere procesamiento de precisión o un negocio que busca adaptarse a los cambios del mercado, el Metalverse ofrece confiabilidad y éxito incomparables.",
				"Con más de 70,000 productos y una gama completa de capacidades de procesamiento, Ryerson asegura que consiga precisamente lo que necesita, cuando lo necesita; todo a precios competitivos.",
			],
			metalSolutions: [
				{
					title: "180+",
					context:
						"Más de 180 años de experiencia en la entrega de valor y servicio en el sector del metal",
				},
				{
					title: "100+",
					context: `Más de 100 depósitos y centros de procesamiento y servicio en ubicaciones estratégicas de América del Norte y China`,
				},
				{
					title: "70K",
					context: `Productos de acero inoxidable, aluminio y carbono`,
				},
			],
		},
		linkText: "Aprender más sobre",
	},
};
