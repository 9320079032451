import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { MetalNearMeProps } from "./MetalNearMe";

export const MetalNearMeContent: LanguageContent<MetalNearMeProps> = {
	en: {
		titleGray: "Metal ",
		titleBlue: "Near Me",
		blurb: `Welcome to the Metalverse: an infinite frontier of metal solutions. Backed by 180+ years of expertise, our \nnetwork of 100+ facilities across North America provides you with expansive support and the personalized touch of local service—no challenge is too great.`,
		heroImageUrl: "./metalNearMeMap.png",
		mobileImageUrl: "./metalNearMeMapMobile.png",
		subHeading: ["100+", "70,000+", "180+"],
		subHeading2: ["Locations", "Products", "Years"],
		subBlurb: [
			"Our Locations are strategically located near you.",
			"Our broad inventory offers metal in all shapes and sizes.",
			"Our experience in the metals industry is unmatched.",
		],
		buttonLabel: [`More about`, `Our locations`],
	},
	fr: {
		titleGray: "Métaux ",
		titleBlue: "près de chez moi",
		blurb: `Bienvenue dans le Metalverse : une ressource infinie de produits métallurgiques personnalisés. Fort de plus de 180 ans d’expertise, notre réseau de plus de 100 installations dans toute l’Amérique du Nord vous offre un vaste soutien et la touche personnalisée d’un service local. Aucun défi n’est trop grand. `,
		heroImageUrl: "./metalNearMeMap.png",
		mobileImageUrl: "./metalNearMeMapMobile.png",
		subHeading: ["Plus de 100 ", "Plus de 70 000 ", "Plus de 180 "],
		subHeading2: ["sites", "produits", "ans"],
		subBlurb: [
			"Nos emplacements sont stratégiquement situés près de chez vous.",
			"Notre vaste stock offre des métaux de toutes formes et de toutes tailles.",
			"Notre expérience dans l’industrie des métaux est inégalée.",
		],
		buttonLabel: ["En savoir plus", "sur nos emplacements"],
	},
	es: {
		titleGray: "Metal ",
		titleBlue: "cerca de mí",
		mobileImageUrl: "./metalNearMeMapMobile.png",
		blurb: `Bienvenido al Metalverse: una frontera infinita de soluciones de metales. Respaldada por más de 180 años de experiencia, nuestra red de más de 100 instalaciones en Norteamérica le proporciona el amplio soporte y el toque personalizado de servicio local; ningún desafío es demasiado grande. `,
		heroImageUrl: "./metalNearMeMap.png",
		subHeading: ["Más de 100", "Más de 70,000", "Más de 180"],
		subHeading2: ["ubicaciones", "productos", "años"],
		subBlurb: [
			"Nuestras ubicaciones están localizadas estratégicamente cerca de usted.",
			"Nuestro amplio inventario ofrece metales en todas las formas y tamaños.",
			"Nuestra experiencia en la industria metalúrgica es incomparable.",
		],
		buttonLabel: ["Más acerca", "de nuestras ubicaciones"],
	},
};
