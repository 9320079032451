/**
 * @sanitizeInput
 * @param input
 * @returns text that has been sanitized and crop to max length
 */
export const sanitizeInput = (input: string, maxLength: number) => {
	return cropText(input.replaceAll(/[&/\\#,+()$~%.^'":*?<>{}]/g, ""), maxLength);
};
// crop text to max length

const cropText = (text: string, length: number) => {
	return text.length > length ? text.substring(0, length) : text;
};

export const appendStyle = (css: string, id: string): void => {
	if (!document?.head) return; // Prevent error if document.head doesn't exist

	if (document.getElementById(id)) return; // Prevent duplicate styles

	const style = document.createElement("style");
	style.id = id; // Assign an ID for easy removal
	style.innerHTML = css;
	document.head.appendChild(style);
};

export const removeStyle = (id: string): void => {
	if (!document?.head) return; // Prevent error if document.head doesn't exist

	const style = document.getElementById(id);
	if (style) {
		document.head.removeChild(style);
	}
};
