import React from "react";
import { Media } from "@ryerson/frontend.layout";
import MetalNearMeDesktop from "./Desktop/MetalNearMeDesktop";
import MetalNearMeMobile from "./Mobile/MetalNearMeMobile";
import { MetalNearMeContent } from "./Content";
import { useApplication } from "@ryerson/frontend.application";

export interface MetalNearMeProps {
	titleGray: string;
	titleBlue: string;
	subtitle?: string;
	blurb: string;
	heroImageUrl: string;
	mobileImageUrl: string;
	subHeading: string[];
	subHeading2: string[];
	subBlurb: string[];
	buttonLabel: string[];
}

const MetalNearMe: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<>
			<Media lessThan="lg">
				<MetalNearMeMobile {...MetalNearMeContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<MetalNearMeDesktop {...MetalNearMeContent[language]} />
			</Media>
		</>
	);
};

export default MetalNearMe;
