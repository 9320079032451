import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { useApplication } from "@ryerson/frontend.application";
import MetalProcessingDesktop from "./Desktop/MetalProcessingDesktop";
import MetalProcessingMobile from "./Mobile/MetalProcessingMobile";
import { MetalProcessingContent } from "./Content";

export interface HoverSlideCardSection {
	titleGray: string;
	titleBlue: string;
	blurb?: string;
	slideCards?: SlideCard[];
	buttonLabel: string[];
}

export interface SlideCard {
	slideCardTitle: string;
	slideCardBackgroundImage: string;
	slideCardImage: string;
	slideCardBlurb: string;
	buttonLinkUrl: string;
	wideCard?: SlideCard;
}

const MetalProcessing: React.FC = () => {
	const {
		localization: { language },
	} = useApplication();
	return (
		<>
			<Media lessThan="lg">
				<MetalProcessingMobile {...MetalProcessingContent[language]} />
			</Media>
			<Media greaterThanOrEqual="lg">
				<MetalProcessingDesktop {...MetalProcessingContent[language]} />
			</Media>
		</>
	);
};

export default MetalProcessing;
