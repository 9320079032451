import React from "react";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Media } from "@ryerson/frontend.layout";
import { Icon, IconList } from "@ryerson/frontend.assets";
interface ListImageProps {
	imageUrl?: string;
	icon: keyof IconList;
	description?: string;
	mobile?: boolean;
}

const Section = styled(Flex)`
	${(props: { mobile: boolean }) => {
		const { mobile } = props;
		return css`
			padding-top: ${mobile ? 0 : "20px"};
			padding-bottom: ${mobile ? "10px" : 0};
		`;
	}}
`;

const ListImage: React.FC<ListImageProps> = ({
	imageUrl = "",
	icon = "solution-carbon-sheet-solutions",
	description = "",
	mobile = false,
}) => {
	const { theme } = useTheme();

	return (
		<Section mobile={mobile}>
			<FlexItem>
				{imageUrl ? (
					<img src={imageUrl} />
				) : (
					<Icon
						icon={icon}
						size="xl"
						css={css`
							margin-bottom: 10px;
						`}
					/>
				)}
			</FlexItem>
			<FlexItem
				alignSelf="center"
				css={css`
					padding-left: 20px;
				`}
			>
				<Media greaterThanOrEqual="lg">
					<Typography size="sm" weight="bold" color={theme.colors.primary.secondaryBrand}>
						{description}
					</Typography>
				</Media>
				<Media lessThan="lg">
					<Typography size="md" weight="bold" color={theme.colors.primary.secondaryBrand}>
						{description}
					</Typography>
				</Media>
			</FlexItem>
		</Section>
	);
};
export default ListImage;
