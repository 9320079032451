import { MetaContent } from "@components/Shared/model/MetaHelmet";
import { StaticPageContent } from "@components/Shared/model/StaticPageContent";
import { CareersContent } from "@components/AboutUs/Careers/Careers";
import { LanguageContent } from "@components/Shared/model/LanguageContent";
import { LeadershipTeamContent } from "@components/AboutUs/LeadershipTeam/LeadershipTeam";
import { FamilyOfCompaniesContent } from "@components/AboutUs/FamilyOfCompanies/FamilyOfCompanies";
import { SolutionsAndCapabilitiesContent } from "@components/AboutUs/SolutionsAndCapabilities/SolutionsAndCapabilities";
import { OurHistoryContent } from "@components/AboutUs/OurHistory/OurHistory";
import { AboutUsHeroContent } from "@components/AboutUs/Hero/Hero";
import { NewsEventsStaticContent } from "@components/NewsEvents/Landing/Shared";

export const MetaObject: LanguageContent<MetaContent> = {
	en: {
		title: "About Ryerson - Leading Supplier of Metal - Ryerson",
		description: "Learn the story and history of Ryerson the leading supplier of Metal.",
		url: `/about-us`,
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		company: true,
	},
	fr: {
		title: "About Ryerson - Leading Supplier of Metal - Ryerson",
		description: "Learn the story and history of Ryerson the leading supplier of Metal.",
		url: `/about-us`,
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		company: true,
	},
	es: {
		title: "About Ryerson - Leading Supplier of Metal - Ryerson",
		description: "Learn the story and history of Ryerson the leading supplier of Metal.",
		url: `/about-us`,
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		company: true,
	},
};

export const HeroContent: LanguageContent<AboutUsHeroContent> = {
	en: {
		title: "About Us",
		years: "",
		subTitle: "Enter The Metalverse",
		description: [
			`We are Ryerson, one of North America's largest and most trusted metal distributors. With a rich history dating back to 1842, we've grown to over 100 locations and offer a diverse catalog of more than 70,000 metal products. While our roots are in metal distribution, we've evolved to provide comprehensive and innovative solutions to thousands of customers across hundreds of industries.`,
			`At Ryerson, we start with an extensive inventory of aluminum, steel, and stainless steel in a variety of shapes and sizes. This robust selection is enhanced by our state-of-the-art processing capabilities and localized supply chain solutions. Our expansive network ensures efficient logistics management across North America. We call this approach Customized Metal Solutions, designed to be responsive, reliable, and tailored to meet the unique needs of our customers.`,
		],
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		actionUrl: "https://ir.ryerson.com/home/default.aspx",
		actionLabel: ["Investor", "Relations"],
	},
	fr: {
		title: "À propos de nous",
		years: "",
		subTitle: "Entrez dans le Metalverse",
		description: [
			`Nous sommes Ryerson, l’un des distributeurs de métaux les plus importants et les plus fiables d’Amérique du Nord. Avec une riche histoire remontant à 1842, nous sommes présents à plus de 100 emplacements et proposons un portefeuille diversifié de plus de 70 000 produits métalliques. Étant à l’origine une entreprise de distribution de métaux, nous avons évolué pour fournir des solutions complètes et novatrices à des milliers de clients dans des centaines de secteurs.`,
			`Ryerson possède un vaste stock d’aluminium, d’acier et d’acier inoxydable dans une variété de formes et de tailles. Cette sélection robuste est soutenue par nos capacités de traitement de pointe et nos solutions de chaîne d’approvisionnement localisées. Notre vaste réseau assure une gestion logistique efficace partout en Amérique du Nord. Nous appelons cette approche les « produits métallurgiques personnalisés », conçus pour être réactifs, fiables et personnalisables pour répondre aux besoins uniques de nos clients.`,
		],
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		actionUrl: "https://ir.ryerson.com/home/default.aspx",
		actionLabel: ["Investisseur", "Rapports"],
	},
	es: {
		title: "Acerca de nosotros",
		years: "",
		subTitle: "Entre al Metalverse",
		description: [
			`Somos Ryerson, uno de los más grandes y confiables distribuidores de metal. Con una abundante historia que data de 1842, hemos crecido a más de 100 ubicaciones y ofrecemos un diverso catálogo de más de 70,000 productos de metal. Aunque nuestras raíces están en la distribución de metal, hemos evolucionado para ofrecer soluciones completas e innovadoras a miles de clientes en cientos de industrias.`,
			`En Ryerson, comenzamos con un extenso inventario de aluminio, acero y acero inoxidable, en una variedad de formas y tamaños. Esta robusta selección es reforzada por nuestras capacidades de procesamiento de vanguardia y soluciones localizadas de la cadena de suministro. Nuestra red en expansión asegura el manejo eficiente de la logística en Norteamérica. A esto le llamamos soluciones de metales personalizadas, diseñadas para ser reactivas, confiables y personalizadas para adaptarse a las necesidades únicas de nuestros clientes.`,
		],
		imageUrl: "/images/about-us/AboutUsCropped.jpg",
		actionUrl: "https://ir.ryerson.com/home/default.aspx",
		actionLabel: ["Inversor", "Relaciones"],
	},
};

export const SolutionsAndCapabilities: LanguageContent<SolutionsAndCapabilitiesContent> = {
	en: {
		title: "Our Solutions & \n Capabilities",
		description: `No matter the industry, challenge, or the desired outcome, Ryerson has the resources and expertise to help. Explore our solutions, processing capabilities, and industries we serve.`,
		lists: [
			{
				title: "Solutions",
				actionUrl: "/metal-solutions/solutions/",
				actionLabel: ["More About", "Metal Solutions"],
				listItems: [
					{
						icon: "solution-advanced-processing",
						label: "Advanced Processing",
						url: "/metal-solutions/solutions/advanced-processing/",
					},
					{
						icon: "solution-global-accounts",
						label: "Global Accounts",
						url: "/metal-solutions/solutions/global-accounts/",
					},
					{
						icon: "solution-carbon-sheet-solutions",
						label: "Carbon Sheet Solutions",
						url: "/metal-solutions/solutions/carbon-sheet-solutions/",
					},
					{
						icon: "solution-custom-aluminum-extrusions",
						label: "Custom Aluminum Extrusions",
						url: "/metal-solutions/solutions/custom-aluminum-extrusions/",
					},
				],
			},
			{
				title: "Capabilities",
				actionUrl: "/metal-solutions/capabilities/",
				actionLabel: ["More About", "Our Capabilities"],
				listItems: [
					{
						icon: "solution-sheet-coil-processing",
						label: "Sheet & Coil Processing",
						url: "/metal-solutions/capabilities/sheet-coil-processing/",
					},
					{
						icon: "solution-plate-processing",
						label: "Plate Processing",
						url: "/metal-solutions/capabilities/plate-processing/",
					},
					{
						icon: "solution-bar-tube-structural-processing",
						label: "Bar, Tube, & Structural Processing",
						url: "/metal-solutions/capabilities/bar-tube-structural-processing/",
					},
					{
						icon: "capabilities-fabrication",
						label: "Fabrication",
						url: "/metal-solutions/capabilities/fabrication/",
					},
				],
			},
		],
	},
	fr: {
		title: "Nos solutions \n et capacités",
		description: `Peu importe le secteur d'activité, le défi ou le résultat visé, Ryerson possède les ressources et l'expertise pour vous aider. Explorez nos solutions, nos capacités de traitement et les secteurs que nous servons.`,
		lists: [
			{
				title: "Solutions",
				actionUrl: "/metal-solutions/solutions/",
				actionLabel: ["En savoir plus", "Solutions métalliques"],
				listItems: [
					{
						icon: "solution-advanced-processing",
						label: "Traitement avancé",
						url: "/metal-solutions/solutions/advanced-processing/",
					},
					{
						icon: "solution-global-accounts",
						label: "Comptes mondiaux",
						url: "/metal-solutions/solutions/global-accounts/",
					},
					{
						icon: "solution-carbon-sheet-solutions",
						label: "Solutions de feuilles de carbone",
						url: "/metal-solutions/solutions/carbon-sheet-solutions/",
					},
					{
						icon: "solution-custom-aluminum-extrusions",
						label: "Extrusions en aluminium sur mesure",
						url: "/metal-solutions/solutions/custom-aluminum-extrusions/",
					},
				],
			},
			{
				title: "Capacités",
				actionUrl: "/metal-solutions/capabilities/",
				actionLabel: ["En savoir plus", "Nos capacités"],
				listItems: [
					{
						icon: "solution-sheet-coil-processing",
						label: "Traitement des feuilles et des bobines",
						url: "/metal-solutions/capabilities/sheet-coil-processing/",
					},
					{
						icon: "solution-plate-processing",
						label: "Traitement des plaques",
						url: "/metal-solutions/capabilities/plate-processing/",
					},
					{
						icon: "solution-bar-tube-structural-processing",
						label: "Barre, tube et traitement structurel",
						url: "/metal-solutions/capabilities/bar-tube-structural-processing/",
					},
					{
						icon: "solution-fabrication",
						label: "Fabrication",
						url: "/metal-solutions/capabilities/fabrication/",
					},
				],
			},
		],
	},
	es: {
		title: "Nuestras soluciones \n y capacidades",
		description: `No importa la industria, el desafío o el resultado deseado, Ryerson cuenta con los recursos y conocimiento técnico para ayudar. Explore nuestras soluciones, capacidades de procesamiento e industrias a las que servimos.`,
		lists: [
			{
				title: "Soluciones",
				actionUrl: "/metal-solutions/solutions/",
				actionLabel: ["Más sobre", "Soluciones metálicas"],
				listItems: [
					{
						icon: "solution-advanced-processing",
						label: "Procesamiento Avanzado",
						url: "/metal-solutions/solutions/advanced-processing/",
					},
					{
						icon: "solution-global-accounts",
						label: "Cuentas globales",
						url: "/metal-solutions/solutions/global-accounts/",
					},
					{
						icon: "solution-carbon-sheet-solutions",
						label: "Soluciones de láminas de carbono",
						url: "/metal-solutions/solutions/carbon-sheet-solutions/",
					},
					{
						icon: "solution-custom-aluminum-extrusions",
						label: "Extrusiones de aluminio personalizadas",
						url: "/metal-solutions/solutions/custom-aluminum-extrusions/",
					},
				],
			},
			{
				title: "Capacidades",
				actionUrl: "/metal-solutions/capabilities/",
				actionLabel: ["Más sobre", "Nuestras capacidades"],
				listItems: [
					{
						icon: "solution-sheet-coil-processing",
						label: "Procesamiento de láminas y bobinas",
						url: "/metal-solutions/capabilities/sheet-coil-processing/",
					},
					{
						icon: "solution-plate-processing",
						label: "Procesamiento de placas",
						url: "/metal-solutions/capabilities/plate-processing/",
					},
					{
						icon: "solution-bar-tube-structural-processing",
						label: "Procesamiento de barras, tubos y estructuras",
						url: "/metal-solutions/capabilities/bar-tube-structural-processing/",
					},
					{
						icon: "solution-fabrication",
						label: "Fabricación",
						url: "/metal-solutions/capabilities/fabrication/",
					},
				],
			},
		],
	},
};

export const OurHistory: LanguageContent<OurHistoryContent> = {
	en: {
		title1: "Our",
		title2: "History",
		years: "1842",
		subTitle: "a modest start as a steel shop",
		superDescription: "Learn more about our legacy of \n delivering value and service in metal",
		description: `From our modest start as a steel shop in 1842, Ryerson operates a network of more than 100 warehouses, service centers, and facilities with the capability and inventory to serve any metal need. We have endured through the Great Chicago Fire, weathered economic downturns and global pandemics, and evolved with the changing markets. Beyond the metal, Ryerson provides Customized Metal Solutions to help your business.`,
		videoId: "mFLpB22mezQ",
		videoOverlay: "180 Years of History",
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		actionUrl: "/about-us/history/",
		actionLabel: ["View Ryerson's", "Full Timeline"],
	},
	fr: {
		title1: "Histoire",
		title2: "",
		years: "1842",
		subTitle: "Un départ modeste en tant que fonderie",
		superDescription:
			"En savoir plus sur notre héritage de \n offrir de la valeur et du service dans le métal",
		description: `Depuis ses débuts modestes en tant que fonderie en 1842, Ryerson est devenue une société qui exploite un réseau de plus de 100 entrepôts, centres de service et installations avec la capacité et les stocks nécessaires pour répondre à tous les besoins en métaux. Nous avons survécu le grand incendie de Chicago, résisté aux ralentissements économiques et aux pandémies mondiales, et avons évolué avec les marchés. Au-delà des métaux, Ryerson fournit des solutions métallurgiques personnalisées pour aider votre entreprise.`,
		videoId: "mFLpB22mezQ",
		videoOverlay: "180 ans d'histoire",
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		actionUrl: "/about-us/history/",
		actionLabel: ["Afficher le chronogramme", "complet de Ryerson"],
	},
	es: {
		title1: "Historia",
		title2: "",
		years: "1842",
		subTitle: "un inicio modesto como un taller de acero",
		superDescription:
			"Conozca más sobre nuestro legado de \n entregando valor y servicio en metal",
		description: `Desde su modesto inicio en 1842 como un taller de acero, Ryerson opera una red de más de 100 almacenes, centros de servicio e instalaciones con la capacidad y el inventario para entregar cualquier necesidad de metales. Hemos resistido al gran incendio de Chicago, soportado cambios económicos desfavorables y pandemias globales, y evolucionado con los cambios del mercado. Más allá del metal, Ryerson ofrece soluciones de metal personalizadas para ayuda a su negocio.`,
		videoId: "mFLpB22mezQ",
		videoOverlay: "180 años de historia",
		videoThumbnail: "/images/about-us/175YearsOfHistory.jpg",
		actionUrl: "/about-us/history/",
		actionLabel: ["ver la línea", "de tiempo completa de ryerson"],
	},
};

export const FamilyOfCompanies: LanguageContent<FamilyOfCompaniesContent> = {
	en: {
		title: "RYERSON",
		subTitle: "Family of Companies",
		tagLine: ["Knowledge.", "Value.", "Expertise.", "It's all in the family."],
		videoId: "ccXie8DMaxQ",
		videoOverlay: "Value Runs in the Family",
		videoThumbnail: "/images/about-us/ValueRunsInTheFamily.jpg",
		description: `The Ryerson Family of Companies consists of multiple brands within the 
            organization, all of which offers distinct value and expertise that allow us to 
            serve our customers better.`,
		actionLabel: ["Learn More About Ryerson", "Family of Companies"],
		actionUrl: "/about-us/family-of-companies",
	},
	fr: {
		title: "RYERSON",
		subTitle: "Famille d’entreprises",
		tagLine: ["Connaissances.", "Valeurs.", "Expertise.", "La famille offre tout çà"],
		videoId: "ccXie8DMaxQ",
		videoOverlay: "La valeur est dans la famille",
		videoThumbnail: "/images/about-us/ValueRunsInTheFamily.jpg",
		description: `La famille d’entreprises Ryerson comprend plusieurs marques au sein de l’organisation, chacune offrant une valeur et une expertise distincte, afin de mieux servir nos clients.`,
		actionLabel: ["En savoir plus sur", "la famille d'entreprises Ryerson"],
		actionUrl: "/about-us/family-of-companies",
	},
	es: {
		title: "RYERSON",
		subTitle: "Familia de compañías",
		tagLine: ["Conocimiento.", "Valor.", "Conocimiento técnico.", "Todo está en la familia"],
		videoId: "ccXie8DMaxQ",
		videoOverlay: "El valor corre en la familia",
		videoThumbnail: "/images/about-us/ValueRunsInTheFamily.jpg",
		description: `La Familia de compañías Ryerson consiste en varias marcas dentro de la organización, todas las cuales ofrecen valor y conocimiento técnico diversos que nos permiten servir mejor a nuestros clientes.`,
		actionLabel: ["conozca más acerca de", "la familia de compañías de Ryerson"],
		actionUrl: "/about-us/family-of-companies",
	},
};

export const CareersSection: LanguageContent<CareersContent> = {
	en: {
		title: "Careers",
		subTitle: "Be a part of history. Join our team.",
		description: `Join Ryerson if you're driven by enthusiasm, a passion for innovation, and the confidence to push boundaries. We welcome individuals with diverse backgrounds and perspectives, fostering an environment where everyone can thrive. At Ryerson, we prioritize internal growth and offer ample resources for long-term career advancement.`,
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Hear from Ryerson \n Team Members",
		buttonLabel: ["Learn More about", "Working at Ryerson"],
		buttonUrl: "/about-us/careers/",
	},
	fr: {
		title: "Carrières",
		subTitle: "Faites partie de l’histoire. Joignez-vous à notre équipe.",
		description: `Joignez-vous à Ryerson si vous êtes motivé par l’enthousiasme, la passion pour l’innovation et la confiance nécessaire pour repousser les limites. Nous accueillons des personnes issues de divers horizons et de diverses perspectives, favorisant ainsi un environnement dans lequel chacun peut s’épanouir. Chez Ryerson, nous accordons la priorité à la croissance interne et offrons de nombreuses ressources pour le développement de carrière à long terme.`,
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Écoutez Ryerson \n Membres de l'équipe",
		buttonLabel: ["En savoir plus sur ", "le travail chez Ryerson"],
		buttonUrl: "/about-us/careers/",
	},
	es: {
		title: "Empleo",
		subTitle: "Sea parte de la historia. Únase a nuestro equipo.",
		description: `Únase a Ryerson si está impulsado por el entusiasmo, con una pasión por la innovación y con la confianza de superar los límites. Damos la bienvenida a personas con diversos orígenes y perspectivas, fomentando un ambiente donde todos pueden prosperar. En Ryerson, damos prioridad al crecimiento interno y ofrecemos una amplia variedad de recursos para su crecimiento de carrera a largo plazo.`,
		videoId: "TA6muHDqS24",
		thumbnailUrl: "/images/careers/MetalExcellence.jpg",
		videoOverlayText: "Escuche de Ryerson \n Miembros del equipo",
		buttonLabel: ["Conocer más acerca ", "de trabajar en Ryerson"],
		buttonUrl: "/about-us/careers/",
	},
};

export const LeadershipTeam: LanguageContent<LeadershipTeamContent> = {
	en: {
		title1: "Leadership",
		title2: "Team",
		description: `Inspiration starts at the top. At Ryerson, our leaders have the experience and expertise to create a world-class organization—but that's simply table stakes. What sets them apart is their ongoing commitment to cultivate the culture and desire to continually transform the customer experience.`,
		actionUrl: "/about-us/leadership-team",
		actionLabel: "View The Entire \n Leadership Team",
	},
	fr: {
		title1: "Équipe ",
		title2: "de direction",
		description: `L'inspiration commence au niveau de la direction. Chez Ryerson, nos dirigeants ont l'expérience et l'expertise nécessaires pour créer une organisation de classe mondiale, mais ce ne sont que des enjeux de table. Ce qui les distingue, c'est leur engagement continu à cultiver la culture et le désir de transformer continuellement l'expérience client.`,
		actionUrl: "/about-us/leadership-team",
		actionLabel: "Voir toute \n L'équipe de direction",
	},
	es: {
		title1: "Equipo ",
		title2: "de liderazgo",
		description: `La inspiración comienza desde arriba. En Ryerson, nuestros líderes tienen la experiencia y el conocimiento técnico para crear una organización de clase mundial, pero eso es jugar sin límites. Lo que los hace diferentes es su compromiso continuo de fomentar la cultura y el deseo de transformar continuamente la experiencia del cliente.`,
		actionUrl: "/about-us/leadership-team",
		actionLabel: "Ver todo el \n Equipo de liderazgo",
	},
};

export const NewsEventsContent: LanguageContent<NewsEventsStaticContent> = {
	en: {
		minimal: true,
		title: "",
		description: ``,
		latestNewsLabel: "Latest News",
		viewAllNewsLabel: ["View All News Releases"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Upcoming Events",
		viewAllEventsLabel: "View All Events",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Sort By period: ",
		loadMoreButton: "Load More Events",
		eventsCategories: [
			{
				label: "All Events",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Jan - March",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Apr - June",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "July - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Dec",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
	fr: {
		minimal: true,
		title: "",
		description: ``,
		latestNewsLabel: "Dernières nouvelles",
		viewAllNewsLabel: ["Afficher tous les communiqués de presse"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Événements à venir",
		viewAllEventsLabel: "Afficher tous les événements à venir",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Trier par période: ",
		loadMoreButton: "Charger plus d'événements",
		eventsCategories: [
			{
				label: "All Events",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Jan - March",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Apr - June",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "July - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Dec",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
	es: {
		minimal: true,
		title: "",
		description: ``,
		latestNewsLabel: "Últimas noticias",
		viewAllNewsLabel: ["Ver todas las nuevas publicaciones"],
		viewAllNewsURL: "/about-us/news-events/news",
		upcomingEventsLabel: "Ver todos los eventos próximos",
		viewAllEventsLabel: "Eventos próximos",
		viewAllEventsURL: "/about-us/news-events",
		filterLabel: "Ordenar por período: ",
		loadMoreButton: "Cargar Más Eventos",
		eventsCategories: [
			{
				label: "All Events",
				quarter: "",
				possibleMonths: [""],
			},
			{
				label: "Jan - March",
				quarter: "quarter1",
				possibleMonths: ["01", "02", "03"],
			},
			{
				label: "Apr - June",
				quarter: "quarter2",
				possibleMonths: ["04", "05", "06"],
			},
			{
				label: "July - Sept",
				quarter: "quarter3",
				possibleMonths: ["07", "08", "09"],
			},
			{
				label: "Oct - Dec",
				quarter: "quarter4",
				possibleMonths: ["10", "11", "12"],
			},
		],
	},
};

export const PageContent: StaticPageContent = {
	meta: MetaObject,
	content: [HeroContent],
};
